


















import { debounceProcess } from "@/helpers/debounce";
import useBranch from "@/hooks/useBranch";
import { Option } from "@/models/class/option.class";
import {
  BranchWarehouseParams,
  BranchWarehouseResponseDto,
} from "@/models/interface/branch";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectBranch extends Vue {
  @Prop({ required: false, default: undefined })
  value!: string | LabelInValue | undefined;

  @Prop({ required: false })
  readonly byUser!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  @Prop({ required: false, type: String, default: "default" })
  mode!: "default" | "multiple" | "tags";

  options: Option<BranchWarehouseResponseDto>[] = [];

  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new BranchWarehouseParams();
    params.byUser = this.byUser;
    this.fetchOptions(params);
  }

  fetchOptions(params?: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useBranch();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string | LabelInValue): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { searchBy } = useBranch();
    const params = new BranchWarehouseParams();
    params.byUser = this.byUser;
    params.search = searchBy({ name: val });

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<BranchWarehouseResponseDto> | undefined {
    if (!value) {
      return;
    }

    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    } else {
      return this.options.find(e => value.key === e.key);
    }
  }
}
