import { i18n } from "@/locales";
import { Moment } from "moment";
import { TranslateResult } from "vue-i18n";

export class FormUtils {
  static mandatory(
    message: TranslateResult = i18n.t("lbl_validation_required_error")
  ) {
    return {
      message: () => message,
      required: true,
    };
  }

  static maxOneYear() {
    return {
      validator: (_, value: Moment[], callback: (err?: Error) => void) => {
        try {
          const error = new Error(i18n.t("validation.max-one-year").toString());
          const [start, end] = value;
          const ONE_YEAR = 1;
          if (end.diff(start, "years", true) > ONE_YEAR) {
            callback(error);
          } else {
            callback();
          }
        } catch {
          callback();
        }
      },
    };
  }
}
